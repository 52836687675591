<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-07 09:43:22
 * @LastEditors: ------
 * @LastEditTime: 2021-08-17 15:37:13
-->
// 选择服务类型
<template>
  <div class="big_box">
    <TitleNav class=" ">
      <template #one>
        <span @click="go('home')" class="cursor">
          首页 >
        </span>
      </template>
      <template #two>
        <span @click="go('serveDetail')" class="cursor">
          服务合同管理 >
        </span>
      </template>
      <template #three>
        <span class="cursor"> {{ results.title }}详情页 </span>
      </template>
      <template v-slot:slotCon>
        <div class="pay_box bg_white_color">
          <div class="pay_top">
            <img src="../image/fuwuleixing.png" alt="" />
            <span class="base_333_color ">服务类型</span>
          </div>
          <div class="content base_666_color">
            <div class="con_item ">
              <span>服务类型：</span>&nbsp;

              <input
                class="aSelect"
                type="text"
                :value="results.title"
                disabled
              />
            </div>
            <div class="con_item health">
              <span>护理标准：</span>&nbsp;
              <a-select default-value="请选择" @change="handleChange2">
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) of results.serviceSpecs"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option> </a-select
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span>合同时长：</span>&nbsp;
              <input type="text" class="aSelect aSelect1" v-model="timeMonth" />
              个月
            </div>
            <div class="con_item" v-show="typeNum == 0">
              <span>服务周期：</span>&nbsp;
              <span
                class="day cursor"
                @click="chooseDay(index + 1)"
                v-for="(item, index) in days"
                :key="index"
              >
                <span
                  :class="
                    daysNum.includes(index + 1) ? 'box_smallOne' : 'box_small'
                  "
                ></span>
                {{ item }}
              </span>
              <span @click="allDay" class="cursor"
                ><span class="allDay bg_white_color" ref="allDay"></span>
                全选</span
              >
            </div>
            <div class="con_item" v-show="typeNum == 0">
              <span>服务时间：</span>&nbsp;
              <a-time-picker use12-hours format="h:mm a" @change="onChange" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;至&nbsp;&nbsp;&nbsp;&nbsp;
              <a-time-picker use12-hours format="h:mm a" @change="onChange1" />
            </div>
            <div class="con_item">
              <span>服务开始 / 结束时间：</span>&nbsp;
              <a-range-picker @change="onChange2" />
            </div>
            <div class="button_bottom">
              <button class="nextPage1 cursor base_white_color" @click="goBack">
                返回
              </button>
              <button
                class="nextPage cursor base_white_color"
                @click="nextPage"
              >
                下一步，选择支付方式
              </button>
            </div>
          </div>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn ">
      <template #one>
        <button @click="goContract" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template>
      <template #two>
        <button @click="createUser" class="btn2 cursor base_white_color">
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽
import moment from "moment";
import { getServeTypeCon } from "../api/api"; // 查询用户姓名 手机号
import { getItem } from "../utils/storage";

export default {
  name: "chooseServe",
  data() {
    return {
      days: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      results: [],
      daysNum: [],
      isShow: false,
      s_type: [],
      Info: {
        serviceId: "",
        serviceSpecsId: "",
        // servicePeriod: "",
        // startTime: "",
        // endTime: "",
        appointmentStartTime: "",
        appointmentEndTime: "",
        num: "",
      },
      contractInfo: null,
      typeNum: "",
      timeMonth: "1",
    };
  },
  components: { TitleNav, ebutton },
  async activated() {
    this.contractInfo = getItem("contract");
    this.typeNum = getItem("typeNum");

    if (this.contractInfo.serviceCity == "") {
      this.$message.error("请返回将信息补充完整!");
      return false;
    }
    //请求服务类型
    let res = await getServeTypeCon({
      _t: 1625732025,
      cityId: this.contractInfo.serviceCity,
    });
    console.log(res.data.result[this.typeNum]);
    this.results = res.data.result[this.typeNum];
    this.Info.serviceId = this.results.id;
    // this.$forceUpdate();
  },

  methods: {
    go(key) {
      switch (key) {
        case "home":
          this.$router.push("/home");
          break;
        case "serveDetail":
          this.$router.push("/serveDetail");
          break;
        default:
          break;
      }
    },
    goBack() {
      this.$router.push("/serveDetail");
    },
    goContract() {
      this.$router.push("/serveManage");
    },
    createUser() {
      this.$router.push("/infoOne");
    },
    nextPage() {
      let that = this;
      that.Info.num = that.timeMonth;
      // const returnedTarget = Object.assign(this.Info, this.contractInfo);
      that.$store.commit("setServeInfo", that.Info);
      console.log(that.daysNum, "服务周期的天数");

      if (that.typeNum == "0") {
        //如果服务类型为钟点服务,,,,
        if (
          Object.keys(that.Info).some(function(index) {
            console.log(index, that.Info[index]);
            if (that.Info[index] == "") {
              return true;
            }
          })
        ) {
          that.$message.error("请将信息填写完整!");
        } else {
          that.$router.push("/payment");
        }
      } else if (that.typeNum != "0") {
        if (
          that.Info.appointmentEndTime == "" ||
          that.Info.appointmentStartTime == "" ||
          that.Info.serviceId == "" ||
          that.Info.serviceSpecsId == ""
        ) {
          that.$message.error("请将信息填写完整!");
        } else {
          that.$router.push("/payment");
        }
      }
    },
    onChange(time) {
      //利用 moment 转换时间格式
      if (time._d) {
        let d = moment(time._d).format("YYYY-MM-DD HH:mm:ss");
        this.Info.startTime = d;
      }
    },
    onChange1(time) {
      if (time._d) {
        let d = moment(time._d).format("YYYY-MM-DD HH:mm:ss");
        this.Info.endTime = d;
      }
    },

    handleChange2(e) {
      //护理标准项的 ID
      this.Info.serviceSpecsId = e;
    },

    onChange2(date, dateString) {
      //将时间拼接为后台所需 格式
      console.log(
        dateString,
        dateString[0] + "T" + moment(date[0]._d).format("HH:mm:ss") + ".000Z"
      );
      this.Info.appointmentStartTime =
        dateString[0] + "T" + moment(date[0]._d).format("HH:mm:ss") + ".000Z";
      this.Info.appointmentEndTime =
        dateString[1] + "T" + moment(date[1]._d).format("HH:mm:ss") + ".000Z";
      //得出天数

      // const diffDays = (startDate, endDate) =>
      //   Math.ceil(
      //     Math.abs(new Date(startDate) - new Date(endDate)) /
      //       (1000 * 60 * 60 * 24)
      //   );

      // console.log(diffDays(dateString[0], dateString[1]) + 1, "得出服务总天数");
    },

    chooseDay(key) {
      //服务周期的天数  周一,周二,,,,,

      console.log(this.daysNum, "服务周期的天数");
      if (this.daysNum.includes(key) == false) {
        this.daysNum.push(key);
        this.Info.servicePeriod = this.daysNum.toString();
      } else {
        this.daysNum.forEach(function(item, index, arr) {
          if (item == key) {
            arr.splice(index, 1);
          }
        });
        this.Info.servicePeriod = this.daysNum.toString();
      }
      if (this.daysNum.length == 7) {
        this.isShow = true;
        this.controlShow();
      } else {
        this.isShow = false;
        this.controlNone();
      }
    },
    allDay() {
      // 全选与否
      this.isShow = !this.isShow;
      if (this.isShow) {
        this.daysNum = [1, 2, 3, 4, 5, 6, 7];
        this.Info.servicePeriod = this.daysNum.toString();

        this.controlShow();
      } else {
        this.daysNum = [];
        this.controlNone();
      }
    },

    //动态添加小方框样式
    controlShow() {
      this.$refs.allDay.style.border = "1px solid pink";
      this.$refs.allDay.style.backgroundColor = "var(--themeColor)";
      this.$refs.allDay.style.boxShadow = " 0 0  10px var(--themeColor)";
    },
    controlNone() {
      this.$refs.allDay.style.backgroundColor = "";
      this.$refs.allDay.style.boxShadow = "";
      this.$refs.allDay.style.border = "";
    },
  },
};
</script>

<style scoped>
.big_box {
  position: relative;
}

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}

.pay_box {
  width: 1520px;
  height: 627px;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  padding: 46px 42px;
}
.pay_top {
  padding-bottom: 34px;
  border-bottom: 1px solid #e2e2e2;
}
.pay_top img {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
}
.pay_top span {
  font-size: 22px;
}
.aSelect {
  width: 372px;
  height: 48px;
  border: none;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding-left: 20px;
}
.aSelect1 {
  width: 120px;
}
.health .ant-select {
  width: 372px;
}
:deep(.ant-select) {
  /* width: 372px; */
  height: 48px !important;
}
:deep(.ant-select-selector) {
  height: 48px !important;
  border-radius: 8px !important;
  border: 1px solid #d7d7d7 !important;
}
:deep(.ant-select-selector::before) {
  content: "";
  height: 48px;
  width: 48px;
  background-color: rgba(204, 204, 204, 0.527);
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: -1px;
}
:deep(.ant-select-selection-item::before) {
  position: absolute;
  content: "";
  height: 0px;
  width: 0px;
  border: 8px solid;
  border-color: #ccc transparent transparent transparent;
  right: 5px;
  top: 18px;
}
:deep(.ant-select-selector:hover) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  border: 1px solid var(--themeColor) !important;
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}
:deep(.ant-select-selection-item) {
  height: 48px !important;
  line-height: 46px !important;
  font-size: 20px !important;
  margin-left: 8px;
}
:deep(.ant-select-arrow) {
  display: none;
}
:deep(.anticon-close-circle svg) {
  display: none;
}
.content {
  font-size: 20px;
  margin: 16px 0 0 90px;
}
.con_item {
  height: 75px;
  line-height: 75px;
}
.day {
  margin-right: 40px;
}
.box_small {
  width: 22px;
  height: 22px;
  border: 1px solid #d7d7d7;
  display: inline-block;
  vertical-align: text-bottom;
  border-radius: 4px;
  margin-right: 8px;
}
.box_smallOne {
  width: 22px;
  height: 22px;
  border: 1px solid var(--themeColor);
  display: inline-block;
  vertical-align: text-bottom;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
}
.box_smallOne::before {
  content: "";
  width: 20px;
  height: 20px;
  background: url("../image/yes.png") no-repeat;
  position: absolute;
  top: 2px;
  left: 1px;
}
.allDay {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 3px;
  vertical-align: text-top;
}
:deep(.ant-time-picker-input:hover) {
  border: 1px solid var(--themeColor);
}
:deep(.ant-time-picker-input) {
  height: 48px !important;
  width: 180px !important;
  border-radius: 8px;
  font-size: 20px;
  color: #666;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN !important;
}
:deep(.ant-time-picker-input::placeholder) {
  font-size: 20px;
  padding-left: 8px;
}

:deep(.ant-time-picker-icon span svg) {
  width: 20px;
  height: 20px;
  margin-right: -80px;
  margin-top: -4px;
}
:deep(.anticon-close-circle svg) {
  width: 20px;
  height: 20px;
  margin-top: -4px;
  margin-right: -80px;
}

/* ------------------------------------------------------- */
:deep(.ant-calendar-picker:hover .ant-calendar-picker-input) {
  border: 1px solid var(--themeColor) !important;
}

:deep(.ant-calendar-picker-input) {
  width: 550px !important;
  border-radius: 8px;
  height: 48px !important;
  box-shadow: none !important;
}
:deep(.ant-calendar-picker:focus .ant-calendar-picker-input) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-calendar-range-picker-input) {
  font-size: 20px;
  color: #666;
}
:deep(.ant-calendar-range-picker-separator) {
  font-size: 24px;
}
.button_bottom {
  text-align: center;
  margin-top: 20px;
}
.button_bottom button {
  width: 257px;
  height: 64px;
  border-radius: 10px;
  font-size: 20px;
}
.nextPage {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
  margin-left: 56px;
}
.nextPage1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
</style>
